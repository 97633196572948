/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO(props) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = data.site.siteMetadata.description || null;
        const metaImage = data.site.siteMetadata.metaImage || null;
        const title = data.site.siteMetadata.title || "dotHello";
        const lang = data.site.siteMetadata.lang || "enc";


        /**
         * @todo update schemaOrgJSONLD
         */
        const schemaOrgJSONLD = [
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            name: title,
            url: "https://dothello.io",
            sameAs: [
              "https://facebook.com/dothelloworld",
              "https://twitter.com/dothelloworld",
            ],
            description: metaDescription,
          },
        ];

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:locale`,
                content: "en_US",
              },

              {
                property: `og:type`,
                content: `website`,
              },
              { property: "og:site_name", content: "dotHello" },
              { property: "og:url", content: "https://dothello.io" },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              // .concat(
              //   keywords.length > 0
              //     ? {
              //         name: `keywords`,
              //         content: keywords.join(`, `),
              //       }
              //     : []
              // )
              .concat(
                metaImage
                  ? [
                      {
                        property: "og:image",
                        content: metaImage,
                      },
                      {
                        name: "twitter:card",
                        content: "summary_large_image",
                      },
                    ]
                  : [
                      {
                        name: "twitter:card",
                        content: "summary_large_image",
                      },
                    ]
              )
              // .concat(meta)
            }
          >
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  // meta: PropTypes.array,
  // keywords: PropTypes.arrayOf(PropTypes.string),
  // title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        url
        metaImage
        icon
        author
        copyright
        lang
      }
    }
  }
`;
