import React from "react";
import SEO from "../components/seo";
import { Helmet } from "react-helmet";
import "../styles/sass/main.scss";
import "font-awesome/css/font-awesome.min.css";

/**
 * @todo: handle mailchimp better:  src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"

 */
const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <main className="home">{children}</main>
      <Helmet>
      <link rel="canonical" href="https://dothello.io"></link>
        <script  id="mcjs">
          {`
            !function(c,h,i,m,p)
            {
              ((m = c.createElement(h)),
              (p = c.getElementsByTagName(h)[0]),
              (m.async = 1),
              (m.src = i),
              p.parentNode.insertBefore(m, p))
            }
            (document,"script","https://chimpstatic.com/mcjs-connected/js/users/86a11ea1d82d8d7d59e291be1/9264a15dd59cb4487ed9692e7.js");
            `}
        </script>
      </Helmet>
    </>
  );
};

export default Layout;
